import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import config from './config';

const isProduction = () => {
  return config.ENVIRONMENT === 'production' || config.ENVIRONMENT === 'staging';
};

if (isProduction()) {
  /**
   * For capturing global errors, including runtime errors in scripts, and React errors.
   * Does not capture errors inside promises or React components.
   */
  window.onerror = function (message, source, lineno, colno, error) {
    fetch(`${config.API_BASE_URL}/logError`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: message,
        appName: 'guidebook-web',
        source: source,
        lineno: lineno,
        colno: colno,
        error: error ? error.toString() : '',
        severity: 'ERROR',
        type: 'Script Error',
        stack: error ? error.stack : '',
      }),
    });
  };

  /**
   * For handling unhandled promise rejections, ensuring asynchronous errors are caught.
   * Only captures promise rejections, not synchronous errors.
   */
  window.addEventListener('unhandledrejection', function (event) {
    fetch(`${config.API_BASE_URL}/logError`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: event.reason ? event.reason.toString() : '',
        appName: 'guidebook-web',
        type: 'Unhandled Promise Rejection',
        stack: event.reason ? event.reason.stack : '',
        severity: 'ERROR',
      }),
    });
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);

// Register service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then((registration) => {
      console.log('ServiceWorker registration successful with scope: ', registration.scope);

      // Send API_BASE_URL to the service worker
      if (registration.active) {
        registration.active.postMessage({ apiBaseUrl: config.API_BASE_URL });
      }
    }).catch((error) => {
      console.log('ServiceWorker registration failed: ', error);
    });
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
