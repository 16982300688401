import config from '../config';
import './SurveyForm.css';
import React, { useState } from 'react';
import useNavigateWithTracking from '../shared/navigateWithTracking';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { trackErrorEvent, trackEvent } from '../shared/trackingManager';

function LoadingOverlay() {
  return (
    <div className="loading-overlay">
      <div className="loader">Sending...</div>
    </div>
  );
}

function SurveyForm({ guestInfo, surveySettings }) {
  const [firstName] = useState(`${guestInfo.firstName}` || '');
  const [lastName] = useState(`${guestInfo.lastName}` || '');
  const [guests, setGuests] = useState(guestInfo.guests || []);
  const [email, setEmail] = useState(guestInfo.email || '');
  const [petType, setPetType] = useState(guestInfo.petType || '');
  const [specialOccasion, setSpecialOccasion] = useState(guestInfo.specialOccasion || '');
  const [celebrationDetails, setCelebrationDetails] = useState(guestInfo.celebrationDetails || '');
  const [extraInfo, setExtraInfo] = useState(guestInfo.extraInfo || '');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const bookingId = localStorage.getItem('bookingId');
  const navigateWithTracking = useNavigateWithTracking();

  const handleGuestChange = (index, event) => {
    const updatedGuests = [...guests];
    updatedGuests[index][event.target.name] = event.target.value;
    setGuests(updatedGuests);
  };
  
  const addGuest = () => {
    setGuests([...guests, { name: '', age: 'adult' }]);
  };
  
  const removeGuest = (index) => {
    const updatedGuests = [...guests];
    updatedGuests.splice(index, 1);
    setGuests(updatedGuests);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
  
    // Prepare the form data
    const formData = {
      bookingId,
      guests,
      email,
      petType,
      specialOccasion,
      celebrationDetails,
      extraInfo
    };
  
    // POST request to /guestSurvey endpoint
    try {
      const response = await fetch(`${config.API_BASE_URL}/guestSurvey`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        const searchParams = new URLSearchParams(location.search);
        const queryString = searchParams.toString();

        trackEvent('Survey Submitted', formData);
        // Redirect on successful submission
        navigateWithTracking(`/survey-success?${queryString}`, { state: { 
          message: 'Survey submitted successfully! Your host will be in touch with personalized recommendations soon!' 
        } });
      } else {
        trackErrorEvent(response.status);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setIsSubmitting(false);

    } catch (error) {
      console.error('Error submitting survey:', error);
      trackErrorEvent(error);
    }
  };
  
  return (
    <div className="survey">
      {isSubmitting && <LoadingOverlay />}
      {surveySettings.introText ? (
        <p className="survey-intro">{surveySettings.introText}</p>
      ) : (
        <>
          <p className="survey-intro">Hey there!</p>
          <p className="survey-intro">We‘d love to make your stay extra special. Share your preferences with us so we can personalize your experience. Remember, all fields are totally optional!</p>
        </>
      )}

      <form onSubmit={handleSubmit} className="survey-form">

        <label>Guests</label>
        <div className="input-container">
          <input
            type="text"
            name="firstName"
            disabled="true"
            value={firstName}/>
          <input
            type="text"
            name="lastName"
            disabled="true"
            value={lastName}/>
        </div>
        {guests.map((guest, index) => (
          <div className="input-container" key={index}>
            <input
              type="text"
              name="name"
              placeholder="Guest Name"
              value={guest.name}
              onChange={(e) => handleGuestChange(index, e)}/>
            <select name="age" value={guest.age} onChange={(e) => handleGuestChange(index, e)}>
              <option value="adult">Adult (18+)</option>
              <option value="older_child">Young Adult (10-17)</option>
              <option value="younger_child">Child (3-9)</option>
              <option value="infant">Infant (0-2)</option>
            </select>
            <button type="button" onClick={() => removeGuest(index)} className="remove-guest-button">
                <FontAwesomeIcon icon={faMinus} />
              </button>
          </div>
        ))}

        <button type="button" onClick={addGuest} className="add-guest-button form-button">Add Another Guest</button>

        <label>Email</label>
        <p className="disclaimer">We‘ll use this to send you our recommendations.</p>
        <div className="input-container">
          <input
            type="text"
            name="name"
            placeholder=""
            value={email}
            onChange={(e) => setEmail(e.target.value)}/>
        </div>

        {!surveySettings.hidePetsQuestion &&
          <div>
            <label htmlFor="petType">Pets?</label>
            <select name="petType" value={petType} onChange={(e) => setPetType(e.target.value)}>
              <option value="">No Pets</option>
              <option value="small_dog">Small Dog</option>
              <option value="large_dog">Large Dog</option>
              <option value="cat">Cat</option>
              <option value="multiple">Multiple</option>
            </select>
          </div>
        }

        <label htmlFor="specialOccasion">Special Occasion</label>
        <select name="specialOccasion" value={specialOccasion} onChange={(e) => setSpecialOccasion(e.target.value)}>
          <option value="">None</option>
          <option value="birthday">Birthday</option>
          <option value="bachelorette_party">Bachelorette Party</option>
          <option value="bachelor_party">Bachelor Party</option>
          <option value="anniversary">Anniversary</option>
          <option value="other">Other</option>
        </select>

        {specialOccasion && (
          <div>
            <label htmlFor="celebrationDetails">
              {specialOccasion === 'other' ? "What's the celebration?" : "Who's celebrating?"}
            </label>
            <input
              type="text"
              name="celebrationDetails"
              value={celebrationDetails}
              onChange={(e) => setCelebrationDetails(e.target.value)}
            />
          </div>
        )}

        <label htmlFor="extraInfo">Tell us why you‘re coming and any preferences or interests. We want to recommend the right things!</label>
        <textarea
          id="extraInfo"
          value={extraInfo}
          placeholder="I love history and I'm coming for the best coffee in town!"
          onChange={(e) => setExtraInfo(e.target.value)}
        />

        <button type="submit" className="form-button">Submit Survey</button>

      </form>
    </div>
  );
}

SurveyForm.propTypes = {
  guestInfo: PropTypes.object,
  surveySettings: PropTypes.object
};
      
export default SurveyForm;
