import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { trackEvent, setUserProperties } from '../shared/trackingManager';

const AddToHomeScreen = () => {
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    const isRunningAsPWA = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
    const hasShownPrompt = localStorage.getItem('hasShownA2HSPrompt');

    if (!isRunningAsPWA && !hasShownPrompt) {
      setTimeout(() => {
        setShowPrompt(true);
      }, 3000);
    }

    // Set user property for PWA status
    setUserProperties(null, null, { isPWA: isRunningAsPWA });
  }, []);

  const handleClose = () => {
    setShowPrompt(false);
    localStorage.setItem('hasShownA2HSPrompt', 'true');
    trackEvent('A2HS Prompt Closed');
  };

  const handleRemindLater = () => {
    setShowPrompt(false);
    trackEvent('A2HS Prompt Remind Later');
  };

  if (!showPrompt) {
    return null;
  }

  return (
    <div className={`add-to-home-screen ${showPrompt ? 'show' : ''}`}>
      <button className="close-button" onClick={handleClose}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <FontAwesomeIcon icon={faShareSquare} size="2x" className="share-icon" />
      <h3>Add to Home Screen</h3>
      <p>Get easy offline access when you arrive at the property!</p>
      <p>1. Tap the share icon below</p>
      <p>2. Select “Add to Home Screen”</p>
      <button className="remind-later" onClick={handleRemindLater}>Remind me later</button>
    </div>
  );
};

export default AddToHomeScreen;