import React, {  } from 'react';
import Markdown from 'react-markdown';
import PropTypes from 'prop-types';

import NavigationButtons from '../components/NavigationButtons';
import AddToHomeScreen from '../components/AddToHomeScreen';

function Welcome({ data }) {
  const { 
    guestInfo, 
    introductionMessage, 
    propertyImageUrl, 
    houseManualItems, 
    restaurantsMessage, 
    activitiesMessage, 
    provisionsMessage,
    propertyName,
    propertyType,
    propertyAddress,
  } = data;
  const title = guestInfo && guestInfo.firstName ? `Welcome ${guestInfo.firstName}!` : propertyName;
  
  return (
    <div>
      <AddToHomeScreen />
      {introductionMessage ? (
        <div className="welcome-message">
          {propertyImageUrl && (
            <div className="hero-image">
              <img src={propertyImageUrl} alt="Property" />
            </div>
          )}
          <h2>{title}</h2>
          <p><Markdown>{introductionMessage}</Markdown></p>
          <p>{propertyAddress}</p>
        </div>
      ) : (
        <div className="property-info">
          {propertyImageUrl && (
            <div className="hero-image">
              <img src={propertyImageUrl} alt="Property" />
            </div>
          )}
          <div className="property-info-content">
            <h2>{propertyName}</h2>
            <p className="property-address">{propertyAddress}</p>
          </div>
        </div>
      )}
      
      <NavigationButtons 
        propertyType={propertyType}
        showDetails={!!houseManualItems}
        showRestaurants={!!restaurantsMessage}
        showActivities={!!activitiesMessage}
        showProvisions={!!provisionsMessage}
      />
    </div>
  );
}

Welcome.propTypes = {
  data: PropTypes.object.isRequired
};
  
export default Welcome;