import React, { useState, useEffect } from 'react';
import packageJson from '../../package.json'

const DebugView = () => {
  const [appVersion, setAppVersion] = useState('');
  const [bookingId, setBookingId] = useState('');
  const [badgeShown, setBadgeShown] = useState(false);
  const [hasShownA2HSPrompt, setHasShownA2HSPrompt] = useState(false);

  // Load initial data
  useEffect(() => {
    setAppVersion(packageJson.version);

    // Fetch booking ID from localStorage
    const storedBookingId = localStorage.getItem('bookingId');
    setBookingId(storedBookingId || 'No booking ID');

    // Fetch badge state from localStorage
    const badgeState = localStorage.getItem('badgeShown') === 'true';
    setBadgeShown(badgeState);

    // Fetch A2HS prompt state from localStorage
    const a2hsPromptState = localStorage.getItem('hasShownA2HSPrompt') === 'true';
    setHasShownA2HSPrompt(a2hsPromptState);
  }, []);

  // Clear booking ID
  const clearBookingId = () => {
    localStorage.removeItem('bookingId');
    setBookingId('No booking ID');
  };

  // Clear badge shown state
  const clearBadgeState = () => {
    localStorage.removeItem('badgeShown');
    setBadgeShown(false);
  };

  // Clear A2HS prompt state
  const clearA2HSPromptState = () => {
    localStorage.removeItem('hasShownA2HSPrompt');
    setHasShownA2HSPrompt(false);
  };

  return (
    <div>
      <h1>Debug Information</h1>
      <p><strong>App Version:</strong> {appVersion}</p>
      <p><strong>Booking ID:</strong> {bookingId}</p>
      <button onClick={clearBookingId}>Clear Booking ID</button>
      <p><strong>Badge Shown:</strong> {badgeShown ? 'Yes' : 'No'}</p>
      <button onClick={clearBadgeState}>Clear Badge State</button>
      <p><strong>A2HS Prompt Shown:</strong> {hasShownA2HSPrompt ? 'Yes' : 'No'}</p>
      <button onClick={clearA2HSPromptState}>Clear A2HS Prompt State</button>
    </div>
  );
};

export default DebugView;