import './App.css';
import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import config from './config';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { setupLibraries, useTrackEvents, useTrackPageViews, setUserProperties } from './shared/trackingManager';

import Welcome from './screens/Welcome';
import Restaurants from './screens/Restaurants';
import Activities from './screens/Activities';
import Provisions from './screens/Provisions';
import HouseInfo from './screens/HouseInfo';
import HouseInfoDetail from './screens/HouseInfoDetail';
import SurveyForm from './screens/SurveyForm';
import SurveySuccess from './screens/SurveySuccess';
import AskQuestion from './screens/AskQuestion';
import DebugView from './screens/Debug';
import Demo from './screens/Demo';
import InHouse from './screens/InHouse';

import TopBar from './components/TopBar';

import Lottie from 'react-lottie';
import loadingAnimationData from './animations/loading.json';
import errorAnimationData from './animations/error.json';

function App() {
  const [data, setData] = useState('');
  const [error, setError] = useState('');
  const [guideType, setGuideType] = useState('booking');
  const location = useLocation();
  const newBookingId = new URLSearchParams(window.location.search).get('bookingId');
  const otaConfirmation = new URLSearchParams(window.location.search).get('c');
  const propertyId = new URLSearchParams(window.location.search).get('propertyId');
  let bookingId = localStorage.getItem('bookingId');

  const dynamicTitle = data.guestInfo && data.guestInfo.firstName ? `Welcome ${data.guestInfo.firstName} %s` : 'Welcome %s';
  const defaultTitle = data.guestInfo && data.guestInfo.firstName ? `Welcome ${data.guestInfo.firstName}!` : 'Welcome!';
  const image = data ? data.propertyImageUrl : '';

  // Analytics
  setupLibraries(bookingId);
  useTrackEvents();
  useTrackPageViews();

  const ScrollToTop = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  };

  const fetchBookingId = async (otaConfirmation) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/findBooking`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ otaConfirmation }),
      });

      if (!response.ok) {
        throw new Error('Booking not found');
      }

      const result = await response.json();
      return result.bookingId;
    } catch (error) {
      setError('Booking ID required, please contact your host for your custom link.');
      console.error('Error fetching booking ID:', error);
      return null;
    }
  };

  useEffect(() => {
    if (location.pathname === '/demo' || location.pathname.includes('/inhouse/')) {
      return; // Do nothing if on the demo page
    }

    const fetchData = async (id, type) => {
      try {
        const endpoint = type === 'property' ? `${config.API_BASE_URL}/property/${id}` : `${config.API_BASE_URL}/booking/${id}`;
        const options = type === 'property' ? {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ propertyId: id }),
        } : {};
    
        const response = await fetch(endpoint, options);
        if (!response.ok) {
          throw new Error('Booking not found');
        }
    
        const apiData = await response.json();
        let guestName = `${apiData.guestInfo?.firstName} ${apiData.guestInfo?.lastName}`;
        let propertyName = apiData.propertyName;
        setUserProperties(guestName, propertyName);
        setData(apiData);
      } catch {
        setError('Booking ID required, please contact your host for your custom link.');
      }
    };
  
    const prepareDataFetch = async () => {
      if (newBookingId) {
        localStorage.setItem('bookingId', newBookingId);
        bookingId = newBookingId;
      } else if (otaConfirmation) {
        // Fetch new bookingId if otaConfirmation is provided and reset the bookingId
        const fetchedBookingId = await fetchBookingId(otaConfirmation);
  
        if (fetchedBookingId) {
          localStorage.setItem('bookingId', fetchedBookingId);
          bookingId = fetchedBookingId;
          window.history.replaceState(null, null, `?bookingId=${fetchedBookingId}`);
        } else {
          return;
        }
      } else if (propertyId) {
        // Fetch booking data if propertyId is provided
        fetchData(propertyId, 'property');
        setGuideType('property');
        return;
      } else {
        bookingId = localStorage.getItem('bookingId');
      }
  
      if (!bookingId) {
        setError('Booking ID required, please contact your host for your custom link.');
        return;
      }

      fetchData(bookingId, 'booking');
    };
  
    prepareDataFetch();
  }, [newBookingId, otaConfirmation, propertyId]);

  if (error) {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: errorAnimationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    // Track App Load Error
    if (config.ENVIRONMENT === 'production' || config.ENVIRONMENT === 'staging') {
      fetch(`${config.API_BASE_URL}/logError`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: error ? error.toString() : '',
          appName: 'guidebook-web',
          type: 'Custom Error Event : App Failed To Load',
          severity: 'ERROR',
        }),
      });
    }

    return (
      <div className="loading-error-screen">
        <Lottie options={defaultOptions} height={200} width={300} />
        <p>{error}</p>
        <p>For additional assistance, send an email to <a href="mailto:info@welcomecompass.com">info@welcomecompass.com</a>.</p>
      </div>
    ) 
  }

  if (!data && location.pathname !== '/demo' && !location.pathname.includes('/inhouse/')) {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: loadingAnimationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return (
      <div className="loading-error-screen">
        <Lottie options={defaultOptions} height={200} width={300} />
        <p>Looking up your details...</p>
      </div>
    );
  }

  return (
    <div className="app">
      <ScrollToTop />
      <Helmet titleTemplate={dynamicTitle}
              defaultTitle={defaultTitle}>
        <meta name="description" content="Description" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:description" content="Your personal welcome guide." />
        <meta property="og:image" content={image} />

        {/* Twitter */}
        <meta name="twitter:title" content={defaultTitle} />
        <meta name="twitter:description" content="Your personal welcome guide." />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {guideType === 'property' ? (
        <TopBar textOverride={data.propertyName ?? ""} showAskButton={false} />
      ) : data.propertyType === "wedding" ? (
        <TopBar textOverride="Our Special Day" />
      ) : (
        <TopBar arrivalDate={data.guestArrivalDate} />
      )}
      <div className="content">
        <Routes>
          <Route path="/" element={<Welcome data={data}/>} />
          <Route path="/demo" element={<Demo/>} />
          <Route path="/survey" element={<SurveyForm guestInfo={data.guestInfo} surveySettings={data.surveySettings} />} />
          <Route path="/survey-success" element={<SurveySuccess />} />
          <Route path="/details" element={<HouseInfo data={data} />} />
          <Route path="/house-info/*" element={<HouseInfo data={data} />} />
          <Route path="/details/:itemId" element={<HouseInfoDetail houseManualItems={data.houseManualItems} />} />
          <Route path="/restaurants" element={<Restaurants data={data}/>} />
          <Route path="/activities" element={<Activities data={data}/>} />
          <Route path="/provisions" element={<Provisions data={data}/>} />
          <Route path="/ask" element={<AskQuestion />} />
          <Route path="/debug" element={<DebugView />} />
          <Route path="/inhouse/:id" element={<InHouse />} />          
        </Routes>
      </div>
    </div>
  );
}

App.propTypes = {
  data: PropTypes.object
};

export default App;
