import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import loadingAnimationData from '../animations/loading.json';
import config from '../config';

function InHouse() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchBooking = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/getCurrentInHouseBooking?propertyId=${id}`);
        if (response.status === 204) {
          setError('No current booking found for this property.');
        } else if (response.ok) {
          const data = await response.json();
          navigate(`/?bookingId=${data.bookingId}`);
        } else {
          throw new Error('Failed to fetch booking');
        }
      } catch (error) {
        console.error('Error fetching booking:', error);
        setError('An error occurred while fetching the booking. Please try again later.');
      }
    };

    fetchBooking();
  }, [id, navigate]);

  if (error) {
    return (
      <div className="error-message">
        <h2>Booking Not Found</h2>
        <p>{error}</p>
      </div>
    );
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="loading-error-screen">
      <Lottie options={defaultOptions} height={200} width={300} />
      <p>Looking up your details...</p>
    </div>
  );
}

export default InHouse;